<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="skill in skills" @click="viewItem(skill)" v-bind:class="{active: skillSelected.id == skill.id}">
							<div class="name">{{ skill.name }}</div>
							<div class="actions">
								<button @click.stop="editItem(skill)"><icon-edit /></button>
								<button @click.stop="showModal('delete', {type: 'skill', from: 'index_skills', model: skill})"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!skills.length">
							{{ $t('skills.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-skill /></div>
								<div class="name">{{ $t('skills.create-new-skill') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
										<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
										<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('general.description') }}</label>
									</div>
									<div class="input-box bg-white">
										<textarea rows="3" :placeholder="$t('skills.description-ph')" v-model="description"></textarea>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.add') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-skill /></div>
								<div class="name">{{ skillSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
									</div>
									<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('general.description') }}</label>
									</div>
									<div class="input-box">
										<textarea rows="3" :placeholder="$t('skills.description-ph')" v-model="description"></textarea>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-skill /></div>
								<div class="name">{{ skillSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(skillSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
								<div class="description">{{ skillSelected.description }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconSkill from '../../Icons/Skill'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconSkill,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				skills: [],
				skillSelected: '',
				name: '',
				description: '',
				error_message: '',
				errorsBe: {}
			}
		},
		async mounted(){
			await this.getSkills()

			setTimeout(() => {
				var title = this.$t('settings-navbar.skills');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshSkillsIndex', () => {
				this.viewCrud = 'create';
				this.getSkills()
			})

			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
		},
		validations: {
			name: {required}
		},
		methods: {
			async getSkills(){
				await axios.get(this.$auth.user().instance.id + '/skills')
				.then(({data}) => {
					this.skills = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						description: this.description
					}

					if(type == 'create'){
						this.createSkill(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateAction(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createSkill(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/skills/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getSkills()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.skillSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(skill){
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.skillSelected = skill

				this.name = skill.name
				this.description = skill.description
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateAction(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/skills/${this.skillSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getSkills()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.skillSelected = this.skills.find(el => el.id == this.skillSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.skillSelected = ''
				}
				this.name = ''
				this.description = ''
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.skillSelected = item
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>

<style lang="scss">
	
</style>